import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogListEntry from '../components/blog-list-entry';
import PageHeader from '../components/page-header';
import siteContent from '../config/site-content';

const BlogPage = ({data}) => (
    <Layout>
        <PageHeader
            heading={siteContent.blog.heading}
            subHeading={siteContent.blog.subheading}
            pageTitle={data.wordpressCategory.yoast_meta.yoast_wpseo_title}
            seoMetaData={data.wordpressCategory.yoast_meta}
        />
        <div className="blog-listing-component">
            <div className="component-wrapper">
                <div className="blog-list">
                    {data.allWordpressPost.edges.map(({node}) => (
                        <BlogListEntry post={node} key={node.slug} />
                    ))}
                </div>
            </div>
        </div>
    </Layout>
);

export default BlogPage;

export const pageQuery = graphql`
    query BlogQuery {
        allWordpressPost {
            edges {
                node {
                    title
                    slug
                    excerpt
                    date(formatString: "Do [of] MMMM, YYYY")
                    author {
                        name
                        link
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                original {
                                    src
                                }
                            }
                        }
                    }
                    acf {
                        featured_image_background_color
                    }
                }
            }
        }
        wordpressCategory(wordpress_id: {eq: 1}) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
        }
    }
`;
